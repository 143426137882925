<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>财务中心</a-breadcrumb-item>
      <a-breadcrumb-item>发票申请</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="dashboard">
      <a-card title="发票申请注意事项">
        <div class="text">
          功能开启后所有用户均可开启发票申请服务
          <a-switch checkedChildren="开" unCheckedChildren="关" :checked="can_apply_invoice=== 1" @change="changeSwitch"/>
        </div>
      </a-card>
      <a-card>
        <div class="clearfix table-tools">
          <div class="buttons">
            <a-form layout='inline'>
              <!-- <a-form-item v-if="canExcelexport">
                <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
              </a-form-item> -->
              <a-form-item>
                <a-button icon="reload" @click="multiAction('check')">批量确认</a-button>
              </a-form-item>
              <a-form-item>
                <a-button type="dashed" icon="delete" @click="multiAction('pullback')">批量取消</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="search">
            <a-form layout='inline'>
              <a-form-item>
                <a-tooltip placement="topLeft" >
                  <template slot="title">
                    <span>学员名称</span>
                  </template>
                  <a-input v-model.trim="searchParams.search.fuzzy" allowClear placeholder="学员名称" style="width: 160px"/>
                </a-tooltip> 
              </a-form-item>
              <a-form-item>
                <a-tooltip placement="topLeft" >
                  <template slot="title">
                    <span>发票抬头</span>
                  </template>
                  <a-input v-model.trim="searchParams.search.company_name" allowClear placeholder="发票抬头" style="width: 160px"/>
                </a-tooltip> 
              </a-form-item>

              <a-form-item>
                <a-radio-group :value="searchParams.search.type">
                  <a-radio-button @click="handleTypeButChange('1')" value="1">
                      个人
                  </a-radio-button>
                  <a-radio-button @click="handleTypeButChange('2')" value="2">
                      公司
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item>
                <a-radio-group :value="searchParams.search.is_send">
                  <a-radio-button @click="handleSendButChange('0')" value="0">
                      未开票
                  </a-radio-button>
                  <a-radio-button @click="handleSendButChange('1')" value="1">
                      已开票
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>



              <a-form-item v-if="canIndex">
                <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <div class="table">
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
            :selection="{key: 'key'}"
            :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
            :columns="columns" :dataSource="list" :scroll="{ x: 1500 }">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
             <template slot="studentName" slot-scope="text, record">
              <!-- <a @click="toProfile(record)">{{ text }}</a> -->
              <LUser :data="record"/>
            </template>

            
            <template slot="company_name" slot-scope="text,record">
              <div v-html="text">{{text}}</div>
            </template>


            <template slot="is_send" slot-scope="text,record">
              <a-switch checkedChildren="已开" unCheckedChildren="未开" :checked="text === 1" @change="changeStatus(record)"/>
            </template>


            <template slot="action" slot-scope="text,record">
              <!-- <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a> -->
              <a href="javascript:;" @click="showEditModal(record)">编辑</a>
            </template>
          </a-table>
        </div>
        <div class="page">
            <a-pagination
              :pageSizeOptions="pageSizeOptions"
              :total="pageParams.totalCount"
              showSizeChanger
              :pageSize="pageParams.perPage"
              v-model="current"
              @change="changePage"
              @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                  <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                  <span v-if="props.value==='100'">100条/页</span>
                </template>
              </a-pagination>
          </div>
      </a-card> 
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:200, dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '订单号', dataIndex: 'out_trade_no', key: 'out_trade_no'},
  { title: '收费名称', dataIndex: 'payment_title', key: 'payment_title'},
  { title: '发票抬头', dataIndex: 'company_name', key: '2' , scopedSlots: { customRender: 'company_name' }},
  { title: '发票类型', dataIndex: 'type', key: '1' ,width:'80px'},
  { title: '金额', dataIndex: 'payment_total', key: '3' ,align:"center",width:'80px'},
  { title: '联系电话', dataIndex: 'company_telphone', key: '4' ,align:"center"},
  { title: '申请时间', dataIndex: 'created_at', key: '5' },
  { title: '开票状态', dataIndex: 'is_send', key: 'is_send', scopedSlots: { customRender: 'is_send' }},
  { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' }}
]

import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import editModal from './editModal'
import cItems from '@/utils/items'

export default {
  name: 'Setting',
  mixins: [ tableMixins , authority],
  components: {
    editModal,
  },
  data() {
    return {
      loading: false,
      exportLoading:false,
      columns,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      invoiceTypes: cItems.invoiceTypes,
      isSends: cItems.isSends,
      can_apply_invoice:1,
      authType:['finance','invoice'],
    }
  },

  created() {
  },

  methods: {
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let res = await this.$store.dispatch('financeInvoiceAction', { data: this.searchParams })
      this.list = res.items
      this.can_apply_invoice = res._data.can_apply_invoice
      this.pageParams = res._meta
      this.loading = false
    },
    async changeSwitch(val) {
      if(this.can_apply_invoice == 1){
        this.can_apply_invoice = 0
      }else{
        this.can_apply_invoice = 1
      }
      let res = await this.$store.dispatch('financeInvoiceSwitchAction', { data: { switch_to: val? 'on': 'off' } })

    },
    async changeStatus(item){
      let res = await this.$store.dispatch('financeInvoiceMultiActionAction', { data: { id: [item.id], action: item.is_send == 1?'pullback':'check' } })
      if (res) {
        this.selectedRowKeys = []
        this.getList()
      }
    },
    async multiAction(type) {
      let res = await this.$store.dispatch('financeInvoiceMultiActionAction', { data: { id: this.selectedRowKeys, action: type } })
      if (res) {
        this.selectedRowKeys = []
        this.getList()
      }
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `确定要删除发票?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('financeInvoicePullbackAction', { data: { id: that.item.id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleTypeButChange(e){
      if(this.searchParams.search.type == e){
        this.searchParams.search.type = undefined
      }else{
        this.searchParams.search.type = e
      }
      this.getList()
    },
    handleSendButChange(e){
      if(this.searchParams.search.is_send == e){
        this.searchParams.search.is_send = undefined
      }else{
        this.searchParams.search.is_send = e
      }
      this.getList()
    },
  }
}
</script>
